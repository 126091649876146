.teacher-bar-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it takes up the full width */
  height: 100%; /* Ensure it takes up the full height */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.teacher-bar-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.teacher-messages-scroll {
  overflow-y: auto;
  flex-grow: 1;
}

.teacher-message-container {
  background-color: #e9f7ef;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.teacher-message-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.teacher-icon {
  color: #007bff;
  font-size: 18px;
  margin-right: 10px;
}

.teacher-icon::before {
  content: "\f19d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.teacher-label {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}

.teacher-message-content {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}