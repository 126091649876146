.documents-bar-container {
  display: flex;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.documents-list {
  width: 150px; /* Can be updated with ½ of the width of the documents-bar-container */
  min-width: 150px;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
  overflow-y: auto;
  padding: 20px;
}

.documents-list h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #343a40;
  padding-bottom: 10px;
  border-bottom: 2px solid #007bff;
}

.document-item {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.document-item:hover {
  background-color: #e9ecef;
}

.document-item.selected {
  background-color: #007bff;
  color: #ffffff;
}

.document-icon {
  min-width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
  font-size: 16px;
}

.document-item.selected .document-icon {
  background-color: #ffffff;
  color: #007bff;
}

.document-title {
  font-size: 16px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.document-content {
  flex-grow: 1;
  padding: 30px;
  overflow-y: auto;
}

.document-content h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #343a40;
}

.content-divider {
  height: 2px;
  background-color: #007bff;
  margin-bottom: 20px;
}

.document-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #495057;
  white-space: pre-wrap;
  margin-bottom: 20px;
}

.nodes {
  margin-top: 20px;
}

.nodes h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #343a40;
}

.node-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.node-item {
  display: inline-block;
  background-color: #e9ecef;
  color: #495057;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.node-item:hover {
  background-color: #dee2e6;
}