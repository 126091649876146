body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

.app-container {
  display: flex;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

.left-section {
  width: 60%;
  margin-right: 20px;
}

.right-section {
  width: 40%;
  background-color: #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.query-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff;
  overflow: hidden;
}

.background-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url('../public/icon_big.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
}

.query-input-container h1 {
  font-size: 4rem;
  color: #333;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  font-weight: 700;
}

.input-wrapper {
  position: relative;
  width:100%;
  max-width: 600px;
  margin: 0 auto;
}

.query-input {
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1.2rem;
  border: 2px solid #4CAF50;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.query-input:focus {
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
}

.submit-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4CAF50;
  transition: color 0.3s ease;
}

.submit-button:hover {
  color: #45a049;
}

.waiting-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff;
  overflow: hidden;
}

.waiting-screen .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.rubber-duck {
  font-size: 8rem;
  margin-bottom: 2rem;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

.loading-circle {
  width: 60px;
  height: 60px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #4CAF50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.waiting-screen p {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
}


.terminate-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.terminate-button:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .left-section, .right-section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .query-input-container h1 {
    font-size: 2rem;
  }

  .input-wrapper {
    width: 90%;
  }
}