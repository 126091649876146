.query-input-container,
.waiting-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  flex-direction: column;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.search-form {
  width: 100%;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  position: relative;
}

h1 {
  width: 100%;
  max-width: 1000px;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.query-input {
  width: 100%;
  padding: 1rem 120px 1rem 20px;
  font-size: 1.2rem;
  border: 2px solid #4CAF50;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.query-input:focus {
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
}

.submit-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.language-selector {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.selected-language {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.selected-language:hover {
  background-color: #e0e0e0;
}

.flag-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.language-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-option:hover {
  background-color: #f0f0f0;
}

.language-option img {
  margin-right: 8px;
}


  
  .loading-circle {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px 0;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .file-input-wrapper {
    margin-right: 10px;
  }
  
  .file-input {
    display: none;
  }
  
  .file-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .file-input-label:hover {
    background-color: #e0e0e0;
  }
  
  .pdf-file-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 15px;
  }
  
  .pdf-file-info svg {
    margin-right: 5px;
  }
  
  .remove-pdf-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .remove-pdf-button:hover {
    color: #ff0000;
  }

  .query-input {
    padding-left: 60px;
  }