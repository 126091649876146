.tab-bar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tab-buttons-container {
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tab-button {
  flex: 1;
  padding: 15px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.tab-button.active {
  background-color: #007bff;
  color: #ffffff;
  font-weight: 600;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}

.tab-content-container {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.graph-container {
  width: 100%;  /* Ensure it takes up the full width */
  height: 100%; /* Ensure it takes up the full height */
  display: flex;
  justify-content: center;
  align-items: center;
}
