:root {
  --primary-color: #3498db;
  --secondary-color: #ecf0f1;
  --text-color: #2c3e50;
  --background-color: #ffffff;
  --border-color: #bdc3c7;
  --user-message-bg: #d5e9f7;
  --rubberduck-message-bg: #fcf3cf;
  --teacher-message-bg: #d4efdf;
  --input-bg: #f8f9fa;
}

body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
}

.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-title {
  background-color: var(--primary-color);
  color: var(--background-color);
  margin: 0;
  padding: 20px;
  font-size: 1.2em;
  font-weight: 600;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  margin-bottom: 20px;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 80%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
}

.message.user {
  background-color: var(--user-message-bg);
  align-self: flex-end;
  margin-left: auto;
  border-bottom-right-radius: 4px;
}

.message.rubberduck {
  background-color: var(--rubberduck-message-bg);
  border-bottom-left-radius: 4px;
}

.message.teacher {
  background-color: var(--teacher-message-bg);
  align-self: flex-end;
  border-bottom-right-radius: 4px;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
}

.message-header .icon {
  margin-right: 8px;
  font-size: 1.2em;
}

.input-area {
  display: flex;
  padding: 16px;
  border-top: 1px solid var(--border-color);
  align-items: flex-end;
  background-color: var(--secondary-color);
}

.input-textarea, .audio-visualizer {
  flex-grow: 1;
  margin-right: 12px;
}

.input-textarea {
  font-family: 'Poppins', sans-serif;
  flex-grow: 1;
  padding: 8px 16px;
  border: 1px solid var(--border-color);
  border-radius: 24px;
  margin-right: 12px;
  resize: none;
  overflow-y: auto;
  height: 24px;
  min-height: 24px;
  max-height: 120px;
  font-size: 1em;
  line-height: 1.5;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--input-bg);
  color: var(--text-color);
}

.input-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

.input-textarea::placeholder {
  color: #95a5a6;
}

.input-textarea::-webkit-scrollbar {
  width: 6px;
}

.input-textarea::-webkit-scrollbar-track {
  background: var(--input-bg);
}

.input-textarea::-webkit-scrollbar-thumb {
  background-color: #bdc3c7;
  border-radius: 3px;
}

.input-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #95a5a6;
}

.send-button {
  padding: 12px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.send-button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.send-button:active {
  transform: scale(0.95);
}

.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9;
}

.input-textarea::-webkit-scrollbar {
  width: 6px;
}

.input-textarea::-webkit-scrollbar-track {
  background: var(--input-bg);
}

.input-textarea::-webkit-scrollbar-thumb {
  background-color: #bdc3c7;
  border-radius: 3px;
}

.input-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #95a5a6;
}

.message pre {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.message code {
  background-color: #f4f4f4;
  border-radius: 3px;
  font-family: monospace;
  padding: 2px 4px;
}

.message p {
  margin-bottom: 10px;
}

.message ul, .message ol {
  margin-bottom: 10px;
  padding-left: 20px;
}

.message blockquote {
  border-left: 3px solid #ccc;
  margin: 0;
  padding-left: 10px;
  color: #777;
}

.voice-button {
  padding: 12px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-right: 8px;
}

.voice-button:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.voice-button.recording {
  background-color: #e74c3c;
  color: var(--background-color);
}

.voice-visualization {
  height: 30px;
  background-color: var(--secondary-color);
  margin-top: 8px;
  border-radius: 15px;
  overflow: hidden;
}

/* Add animation for voice visualization */
@keyframes voiceWave {
  0% { transform: scaleY(0.1); }
  50% { transform: scaleY(1); }
  100% { transform: scaleY(0.1); }
}

.voice-visualization::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 50%, var(--primary-color) 100%);
  animation: voiceWave 1s ease-in-out infinite;
}

.audio-visualizer {
  display: flex;
  align-items: flex-end;
  height: 60px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding: 0 2px;
}

.visualizer-bar {
  flex-grow: 1;
  margin: 0 1px;
  transition: height 0.05s ease;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.audio-visualizer {
  background: rgba(0, 0, 0, 0.1);
}

.visualizer-bar {
  flex-grow: 1;
  background-color: var(--primary-color);
  margin: 0 1px;
  transition: height 0.05s ease;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.visualizer-bar {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
}