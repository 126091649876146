.graph-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.center-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.center-button:hover {
  background-color: #45a049;
}

.center-button svg {
  font-size: 20px; /* Adjust icon size */
}
